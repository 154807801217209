import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap, useMapEvents, LayersControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Box, Button, useTheme } from '@mui/material';
import { useData } from './Context/DataProvider';
import './styles.css'
import NavigationIcon from '@mui/icons-material/Navigation';
import LocationPopup from './components/LocationPopup';



// Mapping of location types to their corresponding symbols
const symbolMap = {
    "hotel": "🏨",
    "restaurant": "🍴",
    "museum": "🖼️",
    "park": "🌳",
    "beach": "🏖️",
    "amusementpark": "🎢",
    "zoo": "🦁",
    "shoppingcenter": "🛍️",
    "historicalsite": "🏰",
    "culturalcenter": "🎭",
    "religioussite": "⛪",
    "sportsfacility": "⚽",
    "landmark": "📍",
    "adventure": "🧗‍♀️",
    "nightlife": "🎉",
    "waterfall": "💧",
    "mountain": "⛰️",
    "hotsprings": "♨️",
    "campingsite": "⛺",
    "lighthouse": "🏝️",
    "scubadiving": "🤿",
    "viewpoint": "👀",
};


const statusMap = {
    "incomplete": "", // Or "☐" (white square) or "⏳" (hourglass)
    "complete": "✔️", // Or "✔️" (check mark)
    "skipped": "⏭️" // Or "🚫" (prohibited) or "⚪" (white circle)
};

export const priorityMap = {
    "mandatory": "❗", // Or "☐" (white square) or "⏳" (hourglass)
    "high": "⭐", // Or "✔️" (check mark)
    "low": "" // Or "🚫" (prohibited) or "⚪" (white circle)
};

const dateColors = {}; // Object to store the assigned color for each date
const previousColors = []; // Array to store the previously used colors

const getColorForDate = (date) => {
    if (!dateColors[date]) {
        // Generate a unique random color for this date, different from the previous one
        let uniqueColor;
        do {
            uniqueColor = `hsl(${Math.random() * 360}, 70%, 50%)`; // Random color
        } while (previousColors.includes(uniqueColor)); // Ensure no color is repeated

        dateColors[date] = uniqueColor; // Store the color for the date
        previousColors.unshift(uniqueColor); // Add the color to the front of the previousColors array

        //keep previousColors length to a maximum of 2, so that only the last two colors are checked.
        if (previousColors.length > 2) {
            previousColors.pop();
        }
    }
    return dateColors[date]; // Return the assigned color for the date
};


const Map = ({ setCenter, setZoom }) => {



    const { locations, routeCoordinates, nextRouteCoordinates, lineRoutes } = useData();
    const theme = useTheme();

    const map = useMapEvents({
        click: () => {
            saveMapPosition()
            // map.locate()
        },
        locationfound: (location) => {
            console.log('location found:', location)
        },
    })



    // Function to save map position and zoom level to localStorage
    const saveMapPosition = () => {
        try {
            if (map) {
                const currentCenter = map.getCenter();
                const currentZoom = map.getZoom();
                // Save the center and zoom level to localStorage
                localStorage.setItem('mapCenter', JSON.stringify(currentCenter));
                localStorage.setItem('mapZoom', currentZoom);
            }
        }
        catch (error) {
            console.error('Map Position Failed')
        }
    };




    // Set interval to save map position and zoom every 10 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
            saveMapPosition();
        }, 10000); // Save every 10 seconds

        // Cleanup interval when component unmounts
        return () => clearInterval(intervalId);
    }, []);




    return (
        <Box>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />


            {locations.map((location, index) => {
                const firstFalseIndex = locations.findIndex(loc => loc.status === 'INCOMPLETE');

                // // Define the marker color based on the status
                // let color = theme.palette.info.light; // Default color


                // Format the date (e.g., MM/DD)
                const formattedDate = new Date(location.date).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                });

                // Get a unique color for the current date
                var uniqueDateColor = getColorForDate(formattedDate);
                var opacity = 1;

                let textColor = theme.palette.common.white;
             


                // var symbol = symbolMap[`${location.attraction}`];
                var attraction = location?.attraction ? location.attraction : null;
                var symbol = attraction ? symbolMap[attraction ?? 'hotel'] : null;

                var status = location?.status ? location.status.toLowerCase() : null;
                var statusSymbol = status ? statusMap[status ?? 'incomplete'] : null;

                var priority = location?.priority ? location.priority.toLowerCase() : null;
                var prioritySymbol = priority ? priorityMap[priority ?? 'low'] : null;


                console.log("CHECK",index,status,statusSymbol)
                // if (status === 'complete') {
                //     uniqueDateColor = theme.palette.grey. // Completed
                // } 

                // Custom SVG icon with number, date, and possibly the location symbol
                const svgIcon = L.divIcon({
                    className: index === firstFalseIndex ? 'flashing-icon' : 'number-icon',
                    html: `
            <svg width="40" height="70" viewBox="0 0 40 60" xmlns="http://www.w3.org/2000/svg">
                <!-- Main Circle -->
                <circle cx="20" cy="20" r="18" fill="${uniqueDateColor}" opacity="${opacity}" />
                <text x="20" y="16" text-anchor="middle" stroke="${textColor}" stroke-width="1px" dy=".3em" fill="white" font-size="14">${index + 1}</text>
                <text x="20" y="32" text-anchor="middle" fill="white" font-size="10" font-weight="bold">${formattedDate}</text>
         

                
            ${prioritySymbol ? `
                   <circle cx="20" cy="40" r="8" fill="${theme.palette.warning.light}" />
                   <text x="20" y="40" text-anchor="middle" fill="${theme.palette.warning.light}" font-size="8" dy=".3em">${prioritySymbol}</text>
               ` : ''}
                ${statusSymbol ? `
                   <circle cx="10" cy="5" r="8" fill="${theme.palette.grey[100]}" />
                   <text x="10" y="5" text-anchor="middle" fill="${theme.palette.grey[300]}" font-size="8" dy=".3em">${statusSymbol}</text>
               ` : ''}
                <!-- Symbol Circle Badge -->
                 ${symbol ? `
                   <circle cx="30" cy="5" r="8" fill="${theme.palette.grey[100]}" />
                   <text x="30" y="5" text-anchor="middle" fill="${theme.palette.grey[300]}" font-size="8" dy=".3em">${symbol}</text>
               ` : ''}
            </svg>
        `,
                    iconSize: [40, 70],  // Adjusted height to accommodate the symbol badge
                    iconAnchor: [20, 50], // Adjust anchor point to center the icon
                });

                const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}&travelmode=driving`;

                return (
                    <Marker icon={svgIcon} key={index} position={[parseFloat(location.lat), parseFloat(location.lng)]}>
                        <LocationPopup location={location} />
                    </Marker>
                );
            })}



            {lineRoutes.length > 0 && (
                <Polyline
                    positions={lineRoutes.map(coord => [coord[1], coord[0]])} // OpenRouteService returns [lng, lat], Leaflet uses [lat, lng]
                    color={theme.palette.warning.dark}
                    weight={4}
                    opacity={1}
                    zIndex={1}
                />
            )}



            {routeCoordinates.length > 0 && (
                <Polyline
                    positions={routeCoordinates.map(coord => [coord[1], coord[0]])} // OpenRouteService returns [lng, lat], Leaflet uses [lat, lng]
                    color={theme.palette.warning.dark}
                    weight={4}
                    opacity={1}
                    zIndex={1}
                />
            )}


            {routeCoordinates.length > 0 && nextRouteCoordinates.length > 0 && (
                <Polyline
                    positions={nextRouteCoordinates.map(coord => [coord[1], coord[0]])} // OpenRouteService returns [lng, lat], Leaflet uses [lat, lng]
                    color={theme.palette.primary.dark}
                    weight={5}
                    opacity={1}
                    zIndex={2}
                />
            )}

        </Box>
    );
};






const MapComponent = ({ contentHeight }) => {

    const [center, setCenter] = useState(null);
    const [zoom, setZoom] = useState(null);
    // Set default center and zoom level
    const defaultCenter = [57.70, 11.97];
    const defaultZoom = 6;

    // Function to get saved map position and zoom from localStorage
    const loadSavedMapPosition = () => {
        const savedCenter = JSON.parse(localStorage.getItem('mapCenter'));
        const savedZoom = parseInt(localStorage.getItem('mapZoom'), 10);

        if (savedCenter) {
            setCenter(savedCenter);
        }
        else {
            setCenter(defaultCenter);
        }

        if (savedZoom) {
            setZoom(savedZoom);
        }
        else {
            setZoom(defaultZoom);
        }
    };


    // Load the saved map position when the component mounts
    useEffect(() => {
        loadSavedMapPosition();
    }, []);




    return (
        <>
            {contentHeight && center && zoom &&
                <MapContainer
                    center={center}
                    zoom={zoom}
                    style={{ height: contentHeight, width: '100%' }}
                >
                    <Map contentHeight={contentHeight} setCenter={setCenter} setZoom={setZoom} />
                </MapContainer>
            }
        </>
    )
}

export default MapComponent;
