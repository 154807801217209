import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  TurnLeft as TurnLeftIcon,
  TurnRight as TurnRightIcon,
  ArrowForward as ArrowForwardIcon,
  RotateLeft as RotateLeftIcon,
  RotateRight as RotateRightIcon,
  ExitToApp as ExitToAppIcon,
  Replay as ReplayIcon,
  Flag as FlagIcon,
  HelpOutline as HelpOutlineIcon,
  ArrowBack as ArrowBackIcon, // Importing the missing ArrowBackIcon
} from '@mui/icons-material';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
export const StepIcon = ({ type }) => {
  switch (type) {
    case 0:
      return <TurnLeftIcon />;
    case 1:
      return <TurnRightIcon />;
    case 2:
      return <TurnLeftIcon />;
    case 3:
      return <TurnRightIcon />;
    case 4:
      return <TurnLeftIcon />;
    case 5:
      return <TurnRightIcon />;
    case 6:
      return <ArrowForwardIcon />;
    case 7:
      return <RoundaboutRightIcon />;
    case 8:
      return <TimeToLeaveIcon />;
    case 9:
      return <ReplayIcon />;
    case 10:
      return <FlagIcon />;
    case 11:
      return <TimeToLeaveIcon />;
    case 12:
      return <ArrowForwardIcon />;
    case 13:
      return <ArrowBackIcon />; // Now this will be correctly imported
    case 14:
      return <HelpOutlineIcon />;
    default:
      return <HelpOutlineIcon />;
  }
};

export default function RouteStepList({ steps }) {
  return (
    <Box sx={{ padding: 2 }}>
      {steps.map((step, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
          <StepIcon type={step.type} />
          <Typography sx={{ marginLeft: 2 }}>
            {step.instruction} - {step.distance} km - {step.duration} min
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
