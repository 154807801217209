import React from 'react';
import { Popup } from 'react-leaflet';
import { Button, Typography, Box, Icon } from '@mui/material';
import NavigationIcon from '@mui/icons-material/Navigation';
import { priorityMap } from '../MapComponent';

const LocationPopup = ({ location }) => {
    const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=$${location.lat},${location.lng}`;
    const textStyle = location.status.toLowerCase() === 'skipped' ? { textDecoration: 'line-through' } : {};

    return (
        <Popup>
            <Box sx={{ padding: 0 }}>
                {location.status && location.status.toLowerCase().includes('skipped') && (
                    <>
                        <Typography sx={{ opacity: 0.7, fontSize: 10 }} variant="subtitle">
                            {`${location.status.toUpperCase()}`}
                        </Typography>
                        <br />
                    </>
                )}
                {location.priority && !location.priority.toLowerCase().includes('none') && (
                    <Typography sx={{ opacity: 0.7, fontSize: 10, ...textStyle }} variant="subtitle">
                        {`PRIORITY: ${location.priority.toUpperCase()}`}
                    </Typography>
                )}
                {location.location && (
                    <Typography variant="h6" gutterBottom={!location.attraction} sx={textStyle}>
                        {location.location}
                    </Typography>
                )}
                {location.attraction && !location.attraction.toLowerCase().includes('none') && (
                    <Box sx={{ mb: 2 }}>
                        <Typography sx={{ opacity: 0.7, fontSize: 10, ...textStyle }} variant="subtitle">
                            {`${location.attraction.toUpperCase()}`}
                        </Typography>
                        <br />
                    </Box>
                )}

                {location.date && (
                    <Typography sx={{ marginRight: 1, ...textStyle }} variant="subtitle">
                        <strong>Date:</strong> {location.date}
                    </Typography>
                )}
                {location.hours && (
                    <Typography variant="subtitle" sx={textStyle}>
                        <strong>Hours:</strong> {location.hours}
                    </Typography>
                )}

                {location.events && (
                    <Typography variant="body2" sx={textStyle}>
                        <strong>Events:</strong> {location.events}
                    </Typography>
                )}

                {location.address && (
                    <Typography variant="body2" sx={textStyle}>
                        <strong>Address:</strong> {location.address}
                    </Typography>
                )}

                {location.description && (
                    <Typography variant="body2" sx={textStyle}>
                        <strong>Description:</strong> {location.description}
                    </Typography>
                )}
                {location.notes && (
                    <Typography variant="body2" sx={textStyle}>
                        <strong>Notes:</strong> {location.notes}
                    </Typography>
                )}
                {/* Button to open directions in Google Maps */}
                <Button
                    variant="contained"
                    startIcon={<NavigationIcon />}
                    onClick={() => window.open(directionsUrl, "_blank")}
                    sx={{ marginTop: 2 }}
                >
                    Navigate
                </Button>
            </Box>
        </Popup>
    );
};

export default LocationPopup;