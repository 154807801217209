import React, { useState, useEffect, useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { DataProvider } from './Context/DataProvider';
import MapIcon from '@mui/icons-material/Map';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MapComponent from './MapComponent';
import TableView from './TableView';

const EuropeMain = () => {
  const [value, setValue] = useState(0);
  const [contentHeight, setContentHeight] = useState(window.innerHeight - 56); // Default to window height minus 56px for BottomNavigation
  const bottomNavRef = useRef(null);

  // Update the height when the window resizes or bottom nav height changes
  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const bottomNavHeight = bottomNavRef.current ? bottomNavRef.current.offsetHeight : 56; // Default height
      setContentHeight(windowHeight - bottomNavHeight);
    };

    // Listen to resize events
    window.addEventListener('resize', handleResize);

    // Calculate on mount
    handleResize();

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <DataProvider>
      <Box sx={{ height: `${contentHeight}px`, overflow: 'auto' }}>
        {/* Pass contentHeight to child components */}
        {value === 0 && <MapComponent contentHeight={contentHeight} />}
        {value === 1 && <TableView contentHeight={contentHeight} />}
      </Box>

      <BottomNavigation
        ref={bottomNavRef} // Add ref to measure the height
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 10, // Ensure it's above other content
        }}
      >
        <BottomNavigationAction label="Map" icon={<MapIcon />} />
        <BottomNavigationAction label="List" icon={<FormatListBulletedIcon />} />
      </BottomNavigation>
    </DataProvider>
  );
};

export default EuropeMain;
