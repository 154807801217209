import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useData } from '../Context/DataProvider';
import { duration, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RouteDetails from './RouteDetails';
import { StepIcon } from './StepIcon';
import { formatDuration } from '../functions/time';

export default function BottomDrawer({ selectedRouteIndex, location, open, setOpen }) {
    const { allRouteData, locations } = useData(); // Assuming allRouteData is part of the context

    const [routeSteps, setRouteSteps] = React.useState([])
    const [summary, setSummary] = React.useState(null)
    // Get the steps for the selected route
    const getRouteSteps = (index) => {
        if (!allRouteData) return [];

        const segment = allRouteData?.properties?.segments[index] ?? [];

        if (!segment) return [];
        const steps = segment ? segment.steps : [];
        return steps;
    };


    const getRouteSummary = async (index) => {
        if (!allRouteData) return [];

        const segment = allRouteData?.properties?.segments[index] ?? [];

        if (!segment) return [];
        return {
            distance: segment.distance,
            duration: formatDuration(segment.duration),
        }
    };

    const setup = async (selectedRouteIndex) => {
        const noRouteCount = locations.slice(0, selectedRouteIndex).filter(location => location.route && location.route.includes('n')).length;

        var steps = await getRouteSteps(selectedRouteIndex - noRouteCount - 1) ?? [];
        setRouteSteps(steps)
        var summary = await getRouteSummary(selectedRouteIndex - noRouteCount - 1)
        setSummary(summary)
    }

    React.useEffect(() => {
        setup(selectedRouteIndex)
    }, [selectedRouteIndex, locations])


    // List item for each step in the route
    const list = () => (
        <Box
            sx={{ width: 'auto', height: '100%', paddingBottom: 10 }}
            role="presentation"
        >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
                    <RouteDetails location={location.location} address={location.address} summary={summary} />
                </Box>
                <Box sx={{ zIndex:999,position: 'fixed', top: 20, right: 20, display: 'flex', flexDirection: 'row', flexShrink: 1, alignItems: 'center' }}>
                    <IconButton sx={{ padding: 1}} aria-label="delete" onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <List>
                {/* Render the steps for the selected route */}
                {routeSteps.length > 0 ? (
                    routeSteps.map((step, index) => (
                        <ListItem key={index}>
                                <ListItemIcon>
                                    <StepIcon type={step.type} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={`Step ${index + 1}`}
                                    secondary={
                                        <Box>
                                            <Typography>
                                                {step.instruction}
                                            </Typography>
                                            <Typography variant='subtitle'>
                                                {`Distance: ${step.distance}m | Duration: ${formatDuration(step.duration)}`}
                                            </Typography>
                                        </Box>
                                    }
                                />
                        </ListItem>
                    ))
                ) : (
                    <ListItem>
                        <ListItemText primary="No steps available for this route" />
                    </ListItem>
                )}
            </List>
            <Divider />
        </Box>
    );

    return (
        <div>
            <Drawer
                anchor="bottom"
                open={open} // Controlled by the `open` prop
                onClose={() => setOpen(false)} // Close when clicking outside
            >
                {list()}
            </Drawer>
        </div>
    );
}
