import React from 'react';
import { Box, Typography } from '@mui/material';

const RouteDetails = ({ location, address, summary }) => {
    // Convert distance from meters to kilometers
    const distanceInKm = (summary.distance / 1000).toFixed(2); // Convert to km with 2 decimal places

    // Convert duration from seconds to minutes

    const duration = summary.duration; // Assuming duration is in seconds


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2, gap: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {location}
            </Typography>
            <Typography variant='subtitle2' sx={{ opacity: 0.7, pb: 1 }}>
                {address}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                    <strong>Distance:</strong> {distanceInKm} km
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'normal' }}>
                    <strong>Duration:</strong> {duration}
                </Typography>
            </Box>
        </Box>
    );
};

export default RouteDetails;
