// index.js or App.js
import React from 'react';
import App from './App';
import { ThemeProvider } from './Context/ThemeContext';
import { DialogProvider } from './Context/DialogContext';
import { SnackbarProvider } from './Context/SnackbarContext';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const AppliedContext = () => {
    return (
        <>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
            <ThemeProvider>
                <SnackbarProvider>
                    <DialogProvider>
                        <App />
                    </DialogProvider>
                </SnackbarProvider>
            </ThemeProvider>
            {/* </LocalizationProvider> */}
        </>
    );
};
export default AppliedContext;
