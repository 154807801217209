export const formatDuration = (seconds) => {
    const durationInMinutes = seconds / 60; // Convert seconds to minutes

    // Convert to hours, minutes, and seconds
    const hours = Math.floor(durationInMinutes / 60); // Get the whole number of hours
    const minutes = Math.floor(durationInMinutes % 60); // Get the remaining minutes
    const remainingSeconds = (seconds % 60).toFixed(2); // Round the remaining seconds to two decimal places

    // Format the time based on hours, minutes, or seconds
    if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ${parseFloat(remainingSeconds)} second${parseFloat(remainingSeconds) !== 1 ? 's' : ''}`;
    } else {
        return `${parseFloat(remainingSeconds)} second${parseFloat(remainingSeconds) !== 1 ? 's' : ''}`;
    }
};
