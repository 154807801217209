import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useData } from './Context/DataProvider';
import { alpha, Box, IconButton, useTheme } from '@mui/material';
import BottomDrawer from './components/BottonRouteInfo';
import DirectionsIcon from '@mui/icons-material/Directions';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
export default function TableView({ contentHeight }) {
  const { locations } = useData(); // Retrieve the locations data from useData()
  const [open, setOpen] = React.useState(false)
  const [index, setIndex] = React.useState(null)
  const [location, setLocation] = React.useState('')

  // Function to get the headers dynamically from the first location object
  const getHeaders = (locations) => {
    if (locations.length > 0) {
      return Object.keys(locations[0]); // Use the keys of the first object as the headers
    }
    return [];
  };

  const showRoute = (location, index) => {
    if (index) {
      setLocation(location)
      setIndex(index)
      setOpen(true)
    }
  }

  const theme = useTheme();

  // Find the index of the first location with status 'FALSE'
  const firstFalseIndex = locations.findIndex(loc => loc.status === 'FALSE');

  return (
    <>
      <TableContainer sx={{ maxHeight: contentHeight }} component={Paper}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {/* Dynamically create headers */}
              {getHeaders(locations).map((header) => (
                <TableCell key={header} align="right">
                  {header.charAt(0).toUpperCase() + header.slice(1)} {/* Capitalize first letter */}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Dynamically create rows for each location */}
            {locations.map((location, index) => {
              // Check if this is the first row with status 'FALSE'
              const isFirstFalse = index === firstFalseIndex;

              return (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor:
                      location.status === 'TRUE'
                        ? alpha(theme.palette.success.light, 0.3) // Adjust opacity (0.3 is an example)
                        : isFirstFalse
                          ? alpha(theme.palette.primary.light, 0.5)
                          : theme.palette.grey[200],
                  }}
                >

                  {getHeaders(locations).map((header, innerIndex) => (
                    <TableCell key={header} align="right">
                      {/* {
                        innerIndex == 0 &&
                        !location?.route?.includes('n') &&
                        (!locations[index - 1]?.route?.includes('n') || index === 0) && // Check previous location (or first index)
                        (!locations[index + 1]?.route?.includes('n') || index === locations.length - 1) && // Check next location (or last index)
                        <IconButton aria-label="delete" onClick={() => showRoute(location, index)}>
                          <DirectionsIcon />
                        </IconButton>
                      } */}
                      {/* If value is TRUE, display checked checkbox; if FALSE, display unchecked checkbox */}
                      {
                        innerIndex == 0 ?
                          <Checkbox
                            checked={location[header] === 'COMPLETE' && location[header] !== 'SKIPPED'}
                            indeterminate={location[header] === 'SKIPPED'}
                          />
                          : (
                            location[header] || 'N/A' // Otherwise show the value or 'N/A'
                          )}
                      {/* {location[header] === 'COMPLETE' ? (
                        <Checkbox checked disabled /> // If TRUE, show checked checkbox
                      ) : (location[header] === 'INCOMPLETE' )? (
                        <Checkbox disabled /> // If FALSE, show unchecked checkbox
                      ) : (
                        location[header] || 'N/A' // Otherwise show the value or 'N/A'
                      )} */}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <BottomDrawer
        location={location}
        open={open}
        setOpen={setOpen}
        selectedRouteIndex={index}
      />
    </>
  );
}
