import React, { useContext, useState, useEffect, createContext } from "react"
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../firebase";
import { Backdrop, Box, CircularProgress, IconButton, useTheme } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
const DataContext = createContext()

export function useData() {
    return useContext(DataContext)
}

export function DataProvider({ children }) {

    const [locations, setLocations] = useState([]);
    const [routeCoordinates, setRouteCoordinates] = useState([]);
    const [nextRouteIndex, setNextRouteIndex] = useState([])
    const [nextRouteCoordinates, setNextRouteCoordinates] = useState([])
    const [routeSegments, setRouteSegments] = useState([])
    const [allRouteData, setAllRouteData] = useState([])
    const [lineRoutes, setLineRoutes] = useState([])

    const [loading, setLoading] = useState(false);;
    const theme = useTheme()


    // const findNextRouteCoordinates = (locations, segments, coordinates) => {
    //     const firstFalseIndex = locations.findIndex(location => location.status === "INCOMPLETE");

    //     if (firstFalseIndex === -1) {
    //         setNextRouteIndex(0)
    //         return []; // No location with status false found, return empty array.
    //     }

    //     // Count locations with routeStatus "no" before firstFalseIndex
    //     const noRouteCount = locations.slice(0, firstFalseIndex).filter(location => location.route && location.route.includes('NONE')).length;

    //     // Adjust firstFalseIndex
    //     const adjustedIndex = firstFalseIndex - noRouteCount - 1;

    //     if (!segments || !segments[adjustedIndex] || !segments[adjustedIndex].steps) {
    //         return []; // adjustedIndex does not exist or segments are invalid, return empty array.
    //     }

    //     const steps = segments[adjustedIndex].steps;

    //     if (!steps || steps.length === 0) {
    //         return []; // No steps found, return empty array.
    //     }


    //     const firstStepWayPoint = steps[0].way_points[0];
    //     const lastStepWayPoint = steps[steps.length - 1].way_points[1];

    //     const wayPoints = [firstStepWayPoint, lastStepWayPoint];
    //     return getCoordinateRange(coordinates, wayPoints);
    // };

    // const getCoordinateRange = (coordinates, wayPoints) => {
    //     if (!coordinates || !wayPoints || wayPoints.length < 2) {
    //         return []; // Return an empty array if inputs are invalid
    //     }

    //     const startCoord = wayPoints[0];
    //     const endCoord = wayPoints[1];

    //     // Validate that startCoord and endCoord are within the bounds of the array
    //     if (startCoord < 0 || endCoord >= coordinates.length || startCoord > endCoord) {
    //         return []; // Return an empty array if indices are invalid
    //     }

    //     return coordinates.slice(startCoord, endCoord + 1); // +1 because slice() excludes the end index
    // };

    // useEffect(() => {
    //     if (locations && locations.length &&
    //         routeSegments && routeSegments.length &&
    //         routeCoordinates && routeCoordinates.length) {
    //         var nextCoordinates = findNextRouteCoordinates(locations, routeSegments, routeCoordinates)
    //         setNextRouteCoordinates(nextCoordinates)
    //     }
    // }, [locations, routeSegments, routeCoordinates])

    const fetchRouteData = async () => {
        setLoading(true)
        try {
            const getRouteData = httpsCallable(firebaseFunctions, 'getRouteData');
            const result = await getRouteData(); // Pass data
            const data = result.data;

            if (!data || !data.locations || !data.route) return;
            const locations = data.locations;
            const segments = data.route.properties.segments;
            const coordinates = data.route.geometry.coordinates;
            setAllRouteData(data.route)
            setLineRoutes(data.lineRoutes)
            setLocations(locations)
            setRouteSegments(segments)
            setRouteCoordinates(coordinates)
            return; // Return the data if needed

        } catch (error) {
            console.error("Error fetching route data:", error);
            // Handle the error appropriately (e.g., show an error message)

        }
        finally {
            setLoading(false)
        }
    };

    useEffect(()=>{
        console.log("locations",locations)
    },[locations])



    useEffect(() => {
        fetchRouteData()
    }, [])




    return (
        <DataContext.Provider value={{
            locations,
            routeCoordinates,
            nextRouteIndex,
            nextRouteCoordinates,
            loading,
            allRouteData,
            lineRoutes
        }}>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{
                    position: 'absolute', top: 10, right: 10, zIndex: 999,
                    backgroundColor: theme.palette.common.white,
                    borderRadius: 1
                }}>
                    <IconButton loading={loading} disabled={loading} onClick={fetchRouteData} size="small" aria-label="delete">
                        <RefreshIcon />
                    </IconButton>
                </Box>
                <Backdrop
                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {children}
            </Box>
        </DataContext.Provider>
    )
}
